import { BlogCategoryDetailFragmentApi, useMainBlogCategoryUrlQueryApi } from 'graphql/generated';
import { useQueryError } from 'hooks/graphQl/UseQueryError';
import { BlogCategoryDetailType, ListedBlogCategoryType } from 'types/blogCategory';

export const useBlogUrl = (): string | undefined => {
    const [{ data, error }] = useMainBlogCategoryUrlQueryApi();
    useQueryError(error);

    if (data?.blogCategories !== undefined && data.blogCategories.length > 0) {
        return data.blogCategories[0].link;
    }

    return undefined;
};

export const mapBlogCategoryDetail = (apiData: BlogCategoryDetailFragmentApi): BlogCategoryDetailType => {
    return {
        ...apiData,
        blogCategoriesTree: apiData.blogCategoriesTree as unknown as ListedBlogCategoryType[],
        __typename: 'BlogCategory',
    };
};
