import Image from 'components/Basic/Image/Image';
import { BannerTypeEnumApi } from 'graphql/generated';
import { twJoin } from 'tailwind-merge';
import { BannerType } from 'types/banner';

export type BannerBelowNoveltiesProps = {
    bannerData: BannerType;
};

const BannerBelowNovelties: FC<BannerBelowNoveltiesProps> = ({ bannerData }) => {
    if (bannerData.type === BannerTypeEnumApi.TypeImageApi) {
        return (
            <a
                className={twJoin(
                    'relative mb-5 block aspect-square w-full bg-cover p-5 text-white no-underline hover:text-grayLighter hover:no-underline',
                    'after:pointer-events-none after:absolute after:left-0 after:right-0 after:top-0 after:bottom-0 after:bg-[linear-gradient(180deg,_rgba(0,_0,_0,_0)_0%,_rgba(0,_0,_0,_0.5)_84.37%)] after:content-[""] active:scale-95',
                )}
                href={bannerData.link ?? '#'}
            >
                <Image
                    src={bannerData.mobileImage}
                    className="object-cover md:hidden"
                    sizes="33vw"
                    fill
                    alt={bannerData.headerText ?? ''}
                />
                <Image
                    src={bannerData.webImage}
                    className="hidden object-cover md:inline"
                    sizes="50vw"
                    fill
                    alt={bannerData.headerText ?? ''}
                />
                <div className="flex aspect-square w-full flex-col justify-end ">
                    {bannerData.headerText && (
                        <div className="z-above mb-2 text-h4 font-bold not-italic">{bannerData.headerText}</div>
                    )}
                    {bannerData.bodyText && (
                        <div className="z-above w-3/4 text-h6 font-medium not-italic">{bannerData.bodyText}</div>
                    )}
                </div>
            </a>
        );
    }

    return <div className="content" dangerouslySetInnerHTML={{ __html: bannerData.html ?? '' }} />;
};

export default BannerBelowNovelties;
