import { useEffectOnce } from 'hooks/ui/useEffectOnce';
import { useState } from 'react';
import { twJoin } from 'tailwind-merge';
import { SliderItemType } from 'types/sliderItem';

type BannersDotProps = {
    index: number;
    isActive: boolean;
    item: SliderItemType;
    scrollTo: (index: number) => () => void;
};

const CarouselDot: FC<BannersDotProps> = ({ item, isActive, index, scrollTo }) => {
    const [start, setStart] = useState(false);

    useEffectOnce(() => setStart(true));

    return (
        <button
            className="flex cursor-pointer flex-col items-center border-none bg-transparent p-0 outline-none vl:px-4 vl:pt-6 vl:pb-3"
            key={item.uuid}
            onClick={scrollTo(index)}
        >
            <span className="hidden text-small font-bold leading-5 tracking-wider text-white duration-200 ease-defaultTransition vl:block">
                {item.dotText}
            </span>
            <div
                className={twJoin(
                    'h-2 w-2 vl:mt-2 vl:h-1 vl:w-40',
                    'after:block after:h-full after:w-full after:rounded-full after:bg-border after:content-[""] vl:after:block vl:after:w-0 vl:after:rounded-sm vl:after:bg-grayDark vl:after:transition-width vl:after:duration-[0s] vl:after:ease-linear',
                    isActive && start && 'after:bg-primary vl:after:w-full vl:after:bg-grayDark vl:after:duration-[5s]',
                )}
            />
        </button>
    );
};

/* @component */
export default CarouselDot;
