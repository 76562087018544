import { getFirstImage } from 'connectors/image/Image';
import { PromotedCategoriesQueryApi, usePromotedCategoriesQueryApi } from 'graphql/generated';
import { useQueryError } from 'hooks/graphQl/UseQueryError';
import { PromotedCategoriesType } from 'types/category';

export function usePromotedCategories(): PromotedCategoriesType[] | undefined {
    const [{ data, error }] = usePromotedCategoriesQueryApi();
    useQueryError(error);

    if (data?.promotedCategories === undefined) {
        return undefined;
    }

    return mapCategoryApiData(data.promotedCategories);
}

const mapCategoryApiData = (apiData: PromotedCategoriesQueryApi['promotedCategories']): PromotedCategoriesType[] => {
    return apiData.map((apiCategory) => {
        return {
            ...apiCategory,
            image: getFirstImage(apiCategory.images),
        };
    });
};
