import CategoryItem from './CategoryItem/CategoryItem';
import CategoryItemShowAll from './CategoryItem/CategoryItemShowAll';
import Button from 'components/Forms/Button/Button';
import { usePromotedCategories } from 'connectors/categories/PromotedCategories';
import { useTypedTranslationFunction } from 'hooks/typescript/UseTypedTranslationFunction';
import { useDomainConfig } from 'hooks/useDomainConfig';
import { ArrowIcon } from 'public/svg/IconsSvg';
import { useState } from 'react';
import { twJoin } from 'tailwind-merge';
import { getInternationalizedStaticUrls } from 'utils/getInternationalizedStaticUrls';

const COUNT_OF_COLLAPSED_CATEGORIES = 6;

const PromotedCategories: FC = () => {
    const promotedCategories = usePromotedCategories();
    const [isExpanded, setIsExpanded] = useState(false);
    const t = useTypedTranslationFunction();
    const { url } = useDomainConfig();
    const [catalogUrl] = getInternationalizedStaticUrls(['/catalog'], url);

    if (!promotedCategories) {
        return null;
    }
    const isAbleToExpand = promotedCategories.length > COUNT_OF_COLLAPSED_CATEGORIES;

    return (
        <div className="mx-2 mb-7 flex flex-col">
            <ul className={twJoin('flex flex-wrap', !isExpanded && isAbleToExpand && 'notVl:limit-height')}>
                {promotedCategories.map((listedItem, index) => (
                    <CategoryItem
                        className={isExpanded || index < COUNT_OF_COLLAPSED_CATEGORIES ? 'block' : 'hidden vl:block'}
                        key={index}
                        listedItem={listedItem}
                    />
                ))}
                <CategoryItemShowAll
                    className={twJoin(!isExpanded && isAbleToExpand && 'hidden vl:block')}
                    slug={catalogUrl}
                    label={t('All categories')}
                />
            </ul>
            {isAbleToExpand && (
                <Button
                    type="button"
                    size="smallNarrow"
                    className="mx-auto w-fit -translate-y-3 vl:hidden"
                    onClick={() => setIsExpanded(!isExpanded)}
                >
                    <ArrowIcon className={twJoin('h-3 w-3 transition', isExpanded ? 'rotate-180' : 'rotate-0')} />
                    {isExpanded ? t('Less categories') : t('More categories')}
                </Button>
            )}
        </div>
    );
};

export default PromotedCategories;
