import { SEARCH_QUERY_PARAMETER_NAME } from 'helpers/queryParams/queryParamNames';
import { useDomainConfig } from 'hooks/useDomainConfig';
import Head from 'next/head';
import { getInternationalizedStaticUrls } from 'utils/getInternationalizedStaticUrls';

export const SearchMetadata: FC = () => {
    const { url } = useDomainConfig();
    const [searchUrl] = getInternationalizedStaticUrls(['/search'], url);

    return (
        <Head>
            <script
                type="application/ld+json"
                id="search-metadata"
                dangerouslySetInnerHTML={{
                    __html: JSON.stringify([
                        {
                            '@context': 'https://schema.org',
                            '@type': 'WebSite',
                            url,
                            potentialAction: {
                                '@type': 'SearchAction',
                                target: {
                                    '@type': 'EntryPoint',
                                    urlTemplate: `${searchUrl}?${SEARCH_QUERY_PARAMETER_NAME}={q}`,
                                },
                                'query-input': 'required name=q',
                            },
                        },
                    ]),
                }}
                key="search-metadata"
            />
        </Head>
    );
};
