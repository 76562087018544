import { ExtendedNextLink } from 'components/Basic/ExtendedNextLink/ExtendedNextLink';
import Image from 'components/Basic/Image/Image';
import { ListedItemPropType } from 'types/simpleNavigation';
import twMerge from 'utils/twMerge';

type ListItemProps = {
    listedItem: ListedItemPropType;
};

export const categoryItemTwClass = 'mb-2 w-1/2 px-1 md:mb-3 md:w-1/4 md:px-1 xl:w-1/5';
export const categoryItemLinkTwClass =
    'flex h-full w-full cursor-pointer flex-col items-center justify-center rounded-lg bg-grayLight px-2 pt-5 pb-1 text-center text-body text-primary no-underline hover:transition-all ease-out duration-200  hover:bg-white hover:no-underline hover:shadow-grayShadow lg:px-6 lg:pb-4 lg:text-h6 active:scale-95';
export const categoryItemImageWrapperTwClass =
    'mb-2 flex h-[70px] w-32 items-center justify-center vl:mb-7 vl:h-[106px] vl:w-44 relative';
export const categoryItemLabelTwClass = 'min-h-[38px] leading-4 lg:min-h-[46px] lg:leading-5';

const CategoryItem: FC<ListItemProps> = ({ listedItem, className }) => {
    return (
        <li className={twMerge(categoryItemTwClass, className)}>
            <ExtendedNextLink
                href={listedItem.link || listedItem.slug}
                type={listedItem.link ? 'static-presumed-category' : 'category'}
                className={categoryItemLinkTwClass}
            >
                <>
                    {'image' in listedItem && (
                        <div className={categoryItemImageWrapperTwClass}>
                            <Image
                                className="h-full w-full mix-blend-multiply"
                                width={176}
                                height={106}
                                src={listedItem.image}
                                alt={listedItem.name}
                            />
                        </div>
                    )}
                    <div className={categoryItemLabelTwClass}>
                        {`${listedItem.name} `}
                        {'totalCount' in listedItem && listedItem.totalCount !== undefined && !listedItem.link && (
                            <span className="inline-block text-tiny leading-6 text-grayDarker lg:text-small lg:leading-5">
                                ({listedItem.totalCount})
                            </span>
                        )}
                    </div>
                </>
            </ExtendedNextLink>
        </li>
    );
};

export default CategoryItem;
