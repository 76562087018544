import { ExtendedNextLink } from 'components/Basic/ExtendedNextLink/ExtendedNextLink';
import Heading from 'components/Basic/Heading/Heading';
import Image from 'components/Basic/Image/Image';
import Link from 'components/Basic/Link/Link';
import { getBannerBackgroundColor } from 'helpers/getBannerBackgroundColor';
import { SliderItemType } from 'types/sliderItem';

type CarouselProps = {
    sliderItem: SliderItemType;
    isActive: boolean;
};

const CarouselItem: FC<CarouselProps> = ({ sliderItem, isActive }) => {
    if (sliderItem.webImages === null) {
        return null;
    }

    return (
        <div className="relative flex flex-[0_0_100%] flex-col">
            <div className="relative h-[250px] w-full md:h-[300px] lg:h-[400px] vl:h-full">
                <Image
                    src={sliderItem.mobileImages}
                    className="object-cover md:hidden"
                    sizes="33vw"
                    priority={isActive}
                    fill
                    alt={sliderItem.heading}
                />
                <Image
                    src={sliderItem.webImages}
                    className="hidden object-cover md:inline"
                    sizes="50vw"
                    priority={isActive}
                    fill
                    alt={sliderItem.heading}
                />
            </div>
            <div className="absolute left-0 right-0 top-0 hidden h-full w-full bg-[linear-gradient(180deg,_rgba(0,0,0,0)_45.83%,_rgba(0,0,0,0.4)_100%)] vl:block" />
            <div
                className="relative left-0 top-[unset] z-above w-full flex-1 p-7 pt-12 vl:absolute vl:left-44 vl:top-0 vl:w-[500px] vl:p-12"
                style={{
                    backgroundColor: getBannerBackgroundColor(
                        sliderItem.backgroundColor,
                        sliderItem.backgroundTransparency,
                    ),
                }}
            >
                <Heading className="mb-5 text-h2 leading-9 text-white vl:text-h1 vl:leading-12" type="h2">
                    {sliderItem.heading}
                </Heading>
                <p className="mb-7 text-white">{sliderItem.text}</p>
                <Link
                    className="bg-white text-h5 text-primary opacity-100 hover:bg-grayLighter hover:text-primary"
                    href={sliderItem.link}
                    isButton
                    size="smallNarrow"
                >
                    {sliderItem.linkText}
                </Link>
            </div>
            <ExtendedNextLink href={sliderItem.link} className="absolute top-0 left-0 right-0 bottom-0" type="static" />
        </div>
    );
};

export default CarouselItem;
