import Image from 'components/Basic/Image/Image';
import { BannerTypeEnumApi } from 'graphql/generated';
import { BannerType } from 'types/banner';

export type BannerTescomaWorldProps = {
    bannerData: BannerType;
};

const tescomaWorldWrapperTwClass = 'w-full text-center no-underline hover:no-underline xl:max-w-[380px] relative';

const BannerTescomaWorld: FC<BannerTescomaWorldProps> = ({ bannerData }) => {
    if (bannerData.type === BannerTypeEnumApi.TypeImageApi) {
        return (
            <a className={tescomaWorldWrapperTwClass} href={bannerData.link ?? '#'}>
                <div className="relative aspect-[335_/_220] xl:aspect-[380_/_250]">
                    <Image
                        src={bannerData.webImage ?? ''}
                        alt={bannerData.bodyText ?? ''}
                        fill
                        sizes="(min-width: 600px) 33vw, 50vw"
                        className="responsive-image block w-full xl:[display:initial]"
                    />
                </div>
                <div className="pt-6">
                    {bannerData.headerText && (
                        <p className="text-h5 font-bold not-italic leading-5">{bannerData.headerText}</p>
                    )}
                    {bannerData.bodyText && (
                        <p className="text-center font-base font-normal not-italic leading-5">{bannerData.bodyText}</p>
                    )}
                </div>
            </a>
        );
    }

    return <a className={tescomaWorldWrapperTwClass} dangerouslySetInnerHTML={{ __html: bannerData.html ?? '' }} />;
};

export default BannerTescomaWorld;
