import { getFirstImage } from 'connectors/image/Image';
import { BannerFragmentApi, BannerPositionEnumApi, useBannerSuiteQueryApi } from 'graphql/generated';
import { useQueryError } from 'hooks/graphQl/UseQueryError';
import { BannerType } from 'types/banner';

export function useBannerSuite(): Record<string, BannerType | null> {
    const [{ data, error }] = useBannerSuiteQueryApi();
    useQueryError(error);

    const bannerSuite: Record<string, BannerType | null> = {};

    bannerSuite[BannerPositionEnumApi.OverFavoriteProductsApi] = mapBanner(
        data?.bannerSuite?.bannerOverFavoriteProducts,
    );
    bannerSuite[BannerPositionEnumApi.BenefitsApi] = mapBanner(data?.bannerSuite?.bannerBenefits);
    bannerSuite[BannerPositionEnumApi.BelowFavoriteCategoriesApi] = mapBanner(
        data?.bannerSuite?.bannerBelowFavoriteCategories,
    );
    bannerSuite[BannerPositionEnumApi.BelowNovelties_1Api] = mapBanner(data?.bannerSuite?.bannerBelowNovelties1);
    bannerSuite[BannerPositionEnumApi.BelowNovelties_2Api] = mapBanner(data?.bannerSuite?.bannerBelowNovelties2);
    bannerSuite[BannerPositionEnumApi.BelowNovelties_3Api] = mapBanner(data?.bannerSuite?.bannerBelowNovelties3);
    bannerSuite[BannerPositionEnumApi.BelowNovelties_4Api] = mapBanner(data?.bannerSuite?.bannerBelowNovelties4);
    bannerSuite[BannerPositionEnumApi.DiscoverTescoma_1Api] = mapBanner(data?.bannerSuite?.bannerDiscoverTescoma1);
    bannerSuite[BannerPositionEnumApi.DiscoverTescoma_2Api] = mapBanner(data?.bannerSuite?.bannerDiscoverTescoma2);
    bannerSuite[BannerPositionEnumApi.DiscoverTescoma_3Api] = mapBanner(data?.bannerSuite?.bannerDiscoverTescoma3);

    return bannerSuite;
}

const mapBanner = (bannerOverFavoriteProducts: BannerFragmentApi | null | undefined): BannerType | null => {
    if (bannerOverFavoriteProducts !== null && bannerOverFavoriteProducts !== undefined) {
        return {
            ...bannerOverFavoriteProducts,
            webImage: getFirstImage(bannerOverFavoriteProducts.webImages),
            mobileImage: getFirstImage(bannerOverFavoriteProducts.mobileImages),
        };
    }

    return null;
};
