import { categoryItemImageWrapperTwClass, categoryItemLabelTwClass, categoryItemLinkTwClass } from './CategoryItem';
import { ExtendedNextLink } from 'components/Basic/ExtendedNextLink/ExtendedNextLink';
import { ChevronRightIcon } from 'public/svg/IconsSvg';
import { twJoin } from 'tailwind-merge';
import twMerge from 'utils/twMerge';

type ListItemProps = {
    slug: string;
    label: string;
};

const CategoryItemShowAll: FC<ListItemProps> = ({ slug, label, className }) => {
    return (
        <li className={twMerge('mb-2 w-full px-1 md:mb-3 md:w-1/4 md:px-1 xl:w-1/5', className)}>
            <ExtendedNextLink href={slug} type="category" className={categoryItemLinkTwClass}>
                <>
                    <div className={categoryItemImageWrapperTwClass}>
                        <div className="flex h-14 w-14 items-center justify-center rounded-round bg-grayMidDarker">
                            <ChevronRightIcon className="ml-1 h-6 w-3" />
                        </div>
                    </div>
                    <div className={twJoin(categoryItemLabelTwClass, 'font-bold')}>{label}</div>
                </>
            </ExtendedNextLink>
        </li>
    );
};

export default CategoryItemShowAll;
