import Image from 'components/Basic/Image/Image';
import Button from 'components/Forms/Button/Button';
import { useBannerSuite } from 'connectors/banners/Banners';
import { BannerPositionEnumApi, BannerTypeEnumApi } from 'graphql/generated';

const BannerBelowFavoriteCategories: FC = () => {
    const bannerData = useBannerSuite()[BannerPositionEnumApi.BelowFavoriteCategoriesApi];

    if (bannerData === null) {
        return null;
    }

    const goToLink = () => {
        if (bannerData.link !== null) {
            window.location.href = bannerData.link;
        }
    };

    if (bannerData.type === BannerTypeEnumApi.TypeImageApi) {
        return (
            <div className="mb-12 flex flex-col bg-grayLight lg:flex-row lg:justify-between lg:rounded-tr-md lg:rounded-br-md">
                <div className="relative w-full lg:w-[250px]">
                    <Image
                        className="responsive-image content cover block w-full lg:[display:initial]"
                        src={bannerData.webImage ?? ''}
                        fill
                        sizes="(min-width: 768px) 125px, 50vw"
                        alt={bannerData.bodyText ?? ''}
                    />
                </div>
                <div className="p-4 pb-0 text-center text-h2 font-light not-italic leading-[133%] text-primary lg:flex lg:max-w-[490px] lg:items-center lg:justify-center lg:p-5">
                    {bannerData.bodyText}
                </div>

                <div className="flex items-center justify-center p-5">
                    {bannerData.buttonText !== null && (
                        <Button className="min-h-[40px] w-[200px] p-0 !text-body" onClick={goToLink} type="button">
                            {bannerData.buttonText}
                        </Button>
                    )}
                </div>
            </div>
        );
    }

    return <div className="content" dangerouslySetInnerHTML={{ __html: bannerData.html ?? '' }}></div>;
};

export default BannerBelowFavoriteCategories;
