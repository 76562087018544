import { AlternateUrlApi } from 'graphql/generated';
import Head from 'next/head';

type HreflangProps = {
    alternativeUrls?: AlternateUrlApi[];
};

const AlternativeUrls: FC<HreflangProps> = ({ alternativeUrls }) => {
    if (alternativeUrls === undefined) {
        return null;
    }

    return (
        <Head>
            {alternativeUrls.map(
                (alternativeUrl) =>
                    alternativeUrl.url !== '' &&
                    alternativeUrl.url && (
                        <link
                            key={alternativeUrl.locale}
                            rel="alternate"
                            hrefLang={alternativeUrl.locale}
                            href={alternativeUrl.url}
                        />
                    ),
            )}
        </Head>
    );
};

export default AlternativeUrls;
