import Image from 'components/Basic/Image/Image';
import Button from 'components/Forms/Button/Button';
import Webline from 'components/Layout/Webline/Webline';
import { useBannerSuite } from 'connectors/banners/Banners';
import { BannerPositionEnumApi, BannerTypeEnumApi } from 'graphql/generated';
import { getBannerBackgroundColor } from 'helpers/getBannerBackgroundColor';

const BannerOverFavoriteProducts: FC = () => {
    const bannerData = useBannerSuite()[BannerPositionEnumApi.OverFavoriteProductsApi];

    if (bannerData === null) {
        return null;
    }

    const goToLink = () => {
        if (bannerData.link !== null) {
            window.location.href = bannerData.link;
        }
    };

    if (bannerData.type === BannerTypeEnumApi.TypeImageApi) {
        return (
            <div className="relative mx-auto mb-12 p-5 pt-0 md:mb-14 md:h-[500px] md:max-w-[1400px] md:px-24 notMd:!bg-none">
                <Image
                    className="hidden w-full object-cover md:inline"
                    src={bannerData.webImage ?? ''}
                    fill
                    sizes="50vw"
                    alt={bannerData.headerText ?? ''}
                />
                <div
                    className="relative bg-none pb-7 md:float-right md:h-[500px] md:max-w-[420px]"
                    style={{
                        backgroundColor: getBannerBackgroundColor(
                            bannerData.backgroundColor,
                            bannerData.backgroundTransparency,
                        ),
                    }}
                >
                    <div className="relative aspect-[335_/_120] md:hidden">
                        <Image
                            className="w-full object-cover md:hidden"
                            src={bannerData.mobileImage ?? ''}
                            fill
                            sizes="33vw"
                            alt={bannerData.headerText ?? ''}
                        />
                    </div>
                    {bannerData.headerText && (
                        <div className="p-7 pb-0 text-h2 font-bold not-italic leading-10 text-white md:pt-14 md:text-h1">
                            {bannerData.headerText}
                        </div>
                    )}
                    {bannerData.bodyText && (
                        <div className="px-7 text-h6 font-normal not-italic leading-7 text-white md:pt-5 md:text-h5">
                            {bannerData.bodyText}
                        </div>
                    )}
                    {bannerData.buttonText !== null && bannerData.link !== null && (
                        <Button
                            className="ml-7 mt-7 min-h-[40px] py-2 px-9 md:mt-14"
                            type="button"
                            variant="secondary"
                            onClick={goToLink}
                        >
                            {bannerData.buttonText}
                        </Button>
                    )}
                </div>
            </div>
        );
    }

    return (
        <Webline>
            <div className="content" dangerouslySetInnerHTML={{ __html: bannerData.html ?? '' }}></div>
        </Webline>
    );
};

export default BannerOverFavoriteProducts;
