const DEFAULT_BACKGROUND_COLOR = '#686562e5';

export const getBannerBackgroundColor = (
    backgroundColor: string | null,
    backgroundTransparency: number | null,
): string => {
    if (backgroundColor === null) {
        return DEFAULT_BACKGROUND_COLOR;
    }
    if (backgroundTransparency === null) {
        return backgroundColor;
    }
    let alpha = Math.round(((100 - backgroundTransparency) / 100) * 255).toString(16);
    if (alpha.length === 1) {
        alpha = `0${alpha}`;
    }

    return `${backgroundColor}${alpha}`;
};
