import CarouselItem from './Carousel/CarouselItem';
import CarouselDot from './CarouselDot';
import { useSliderItems } from 'connectors/sliderItems/SliderItems';
import Autoplay from 'embla-carousel-autoplay';
import useEmblaCarousel from 'embla-carousel-react';
import { ArrowIcon } from 'public/svg/IconsSvg';
import { useCallback, useEffect, useRef, useState } from 'react';
import { twJoin } from 'tailwind-merge';

const TEST_IDENTIFIER = 'blocks-carousel';

const AUTOPLAY_PLUGIN = Autoplay({
    delay: 5000,
    stopOnInteraction: false,
    rootNode: (emblaRoot) => emblaRoot.parentElement,
});

const carouselButtonTwClass =
    'absolute w-[68px] flex justify-center items-center top-0 z-bellowOverlay transition-colors ease-defaultTransition duration-200 outline-none h-full border-none cursor-pointer bg-transparent active:scale-[0.97] hover:[&>div]:bg-grayLighter';

const carouselButtonArrowTwClass =
    'absolute top-[250px] z-bellowOverlay flex h-12 w-12 -translate-y-1/2 items-center justify-center rounded-full bg-white duration-200 ease-defaultTransition md:top-[300px] lg:top-[400px] vl:top-1/2';

const Carousel: FC = () => {
    const sliderItems = useSliderItems();

    const autoplayRef = useRef(AUTOPLAY_PLUGIN);
    const [emblaRef, emblaApi] = useEmblaCarousel({ skipSnaps: false, loop: true }, [autoplayRef.current]);
    const [selectedIndex, setSelectedIndex] = useState(0);

    const scrollNext = useCallback(() => {
        emblaApi?.scrollNext();
        autoplayRef.current.reset();
    }, [emblaApi]);

    const scrollPrev = useCallback(() => {
        emblaApi?.scrollPrev();
        autoplayRef.current.reset();
    }, [emblaApi]);

    const scrollTo = useCallback(
        (index: number) => () => {
            emblaApi?.scrollTo(index);
            autoplayRef.current.reset();
        },
        [emblaApi],
    );

    useEffect(() => {
        if (!emblaApi) {
            return void null;
        }

        const onSelectHandler = () => setSelectedIndex(emblaApi.selectedScrollSnap());
        onSelectHandler();
        emblaApi.on('select', onSelectHandler);

        return () => {
            emblaApi.off('select', onSelectHandler);
        };
    }, [emblaApi]);

    if (sliderItems.length === 0) {
        return null;
    }

    return (
        <div
            className="relative mb-14 h-auto w-full overflow-hidden vl:h-[500px]"
            data-testid={TEST_IDENTIFIER}
            ref={emblaRef}
        >
            <div className="relative flex h-full w-full cursor-pointer">
                {sliderItems.map((item, index) => (
                    <CarouselItem key={item.uuid} sliderItem={item} isActive={index === selectedIndex} />
                ))}
            </div>
            <button className={twJoin(carouselButtonTwClass, 'left-0')} onClick={scrollPrev}>
                <div className={twJoin(carouselButtonArrowTwClass, 'rotate-90')}>
                    <ArrowIcon />
                </div>
            </button>
            <button className={twJoin(carouselButtonTwClass, 'right-0')} onClick={scrollNext}>
                <div className={twJoin(carouselButtonArrowTwClass, '-rotate-90')}>
                    <ArrowIcon />
                </div>
            </button>
            <div className="relative left-0 bottom-0 flex h-10 w-full items-center justify-center gap-x-5 vl:absolute vl:h-auto vl:gap-x-[70px]">
                {sliderItems.map((item, index) => (
                    <CarouselDot
                        index={index}
                        isActive={index === selectedIndex}
                        item={item}
                        key={item.uuid}
                        scrollTo={scrollTo}
                    />
                ))}
            </div>
        </div>
    );
};

/* @component */
export default Carousel;
