import { getFirstImage } from 'connectors/image/Image';
import { SliderItemFragmentApi, useSliderItemsQueryApi } from 'graphql/generated';
import { useQueryError } from 'hooks/graphQl/UseQueryError';
import { useMemo } from 'react';
import { SliderItemType } from 'types/sliderItem';

export const useSliderItems = (): SliderItemType[] => {
    const [{ data, error }] = useSliderItemsQueryApi();
    useQueryError(error);

    return useMemo(() => (data !== undefined ? mapSliderItemsApiData(data.sliderItems) : []), [data]);
};

const mapSliderItemApiData = (apiData: SliderItemFragmentApi): SliderItemType => {
    return {
        ...apiData,
        webImages: getFirstImage(apiData.webImages),
        mobileImages: getFirstImage(apiData.mobileImages),
    };
};

const mapSliderItemsApiData = (apiData: SliderItemFragmentApi[]): SliderItemType[] => {
    return apiData.map((sliderItem) => mapSliderItemApiData(sliderItem));
};
