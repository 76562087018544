import LimitHeightWrap from 'components/Helpers/LimitHeightWrap/LimitHeightWrap';
import UserText from 'components/Helpers/UserText/UserText';
import Webline from 'components/Layout/Webline/Webline';
import { useSettings } from 'connectors/Settings/Settings';
import { useTypedTranslationFunction } from 'hooks/typescript/UseTypedTranslationFunction';

const TEST_IDENTIFIER = 'block-rticle-preview' as const;

const HomepageArticle: FC = () => {
    const t = useTypedTranslationFunction();
    const settings = useSettings();

    if (settings?.homepageArticle === null) {
        return null;
    }

    return (
        <Webline data-testid={TEST_IDENTIFIER}>
            <LimitHeightWrap
                buttonLabelMore={t('show more')}
                buttonLabelLess={t('show less')}
                isWithButtonIcons
                mobileHeight={200}
                desktopHeight={300}
                isDefaultCollapsed
            >
                <div className="mx-auto max-w-[700px]">
                    <UserText htmlContent={settings?.homepageArticle as string} isGrapesJs />
                </div>
            </LimitHeightWrap>
        </Webline>
    );
};

export default HomepageArticle;
