import { useBannerSuite } from 'connectors/banners/Banners';
import { BannerPositionEnumApi, BannerTypeEnumApi } from 'graphql/generated';

const BannerBenefits: FC = () => {
    const bannerData = useBannerSuite()[BannerPositionEnumApi.BenefitsApi];
    if (bannerData?.type === BannerTypeEnumApi.TypeHtmlApi || bannerData?.type === BannerTypeEnumApi.TypeWysiwygApi) {
        /**
         * For default wysiwyg content:
         * @see app/src/Model/Banner/html/_default-wysiwyg.html
         */
        return (
            <section
                className="my-7 mx-auto bg-grayLight md:max-w-[1400px] md:rounded-md md:py-5 md:px-24"
                dangerouslySetInnerHTML={{ __html: bannerData.html ?? '' }}
            />
        );
    }

    // This banner position is only for WYSIWYG editor (or html, same thing)
    return null;
};

export default BannerBenefits;
