import BlogPreviewList from './BlogPreviewList/BlogPreviewList';
import { ExtendedNextLink } from 'components/Basic/ExtendedNextLink/ExtendedNextLink';
import { useBlogPreviewArticles } from 'connectors/articleInterface/blogArticle/BlogArticle';
import { useBlogUrl } from 'connectors/blogCategory/BlogCategory';
import { useTypedTranslationFunction } from 'hooks/typescript/UseTypedTranslationFunction';
import { ChevronRightIcon } from 'public/svg/IconsSvg';
import { memo } from 'react';

const BlogPreview = memo(() => {
    const testIdentifier = 'blocks-blogpreview';

    const t = useTypedTranslationFunction();
    const blogUrl = useBlogUrl();
    const blogItems = useBlogPreviewArticles();

    return (
        <section className="pt-7 pb-10 md:pb-24 md:pt-14" data-testid={testIdentifier}>
            <header className="mb-7 flex items-center md:mb-14">
                <h2 className="mr-5 text-h5 font-bold normal-case md:text-h2">{t('Also read')}</h2>
                {blogUrl !== undefined && (
                    <ExtendedNextLink
                        type="blogCategory"
                        href={blogUrl}
                        className="ml-auto flex items-center whitespace-nowrap text-h6 font-medium text-primary underline decoration-grayDarker underline-offset-4 hover:text-primary hover:decoration-primary md:ml-14"
                    >
                        <>
                            <span>{t('Go to blog')}</span>
                            <ChevronRightIcon className="ml-2 h-2.5 w-1.5 -translate-y-[2px] text-inherit" />
                        </>
                    </ExtendedNextLink>
                )}
            </header>
            <BlogPreviewList items={blogItems} />
        </section>
    );
});

BlogPreview.displayName = 'BlogPreview';

export default BlogPreview;
