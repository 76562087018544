import Heading from 'components/Basic/Heading/Heading';
import BannerTescomaWorld from 'components/Blocks/Banners/Position/BannerTescomaWorld';
import Webline from 'components/Layout/Webline/Webline';
import { useBannerSuite } from 'connectors/banners/Banners';
import { BannerPositionEnumApi } from 'graphql/generated';
import { useTypedTranslationFunction } from 'hooks/typescript/UseTypedTranslationFunction';

const TescomaWorldBannerCollection: FC = () => {
    const t = useTypedTranslationFunction();
    const bannerData = useBannerSuite();

    const tescomaWorld1 = bannerData[BannerPositionEnumApi.DiscoverTescoma_1Api];
    const tescomaWorld2 = bannerData[BannerPositionEnumApi.DiscoverTescoma_2Api];
    const tescomaWorld3 = bannerData[BannerPositionEnumApi.DiscoverTescoma_3Api];

    if (tescomaWorld1 === null && tescomaWorld2 === null && tescomaWorld3 === null) {
        return null;
    }

    return (
        <Webline>
            <div className="my-14">
                <Heading type="h2">{t('Discover Tescoma')}</Heading>
                <div className="mt-7 grid md:grid-cols-[1fr_1fr_1fr] md:gap-x-5">
                    {tescomaWorld1 !== null && <BannerTescomaWorld bannerData={tescomaWorld1} />}
                    {tescomaWorld2 !== null && <BannerTescomaWorld bannerData={tescomaWorld2} />}
                    {tescomaWorld3 !== null && <BannerTescomaWorld bannerData={tescomaWorld3} />}
                </div>
            </div>
        </Webline>
    );
};

export default TescomaWorldBannerCollection;
